// handleNavigate does called in react component
/* eslint-disable react-hooks/rules-of-hooks */

import { useAnalyticsEvents } from '@atlaskit/analytics-next';

import { useRouteActions } from '@confluence/route-manager/entry-points/RouteState';
import { ADVANCED_SEARCH, WIKI } from '@confluence/named-routes';

import type { KeyableInterface } from '../types';

interface handleNavigateInterface {
	searchMore?: string;
	searchResult?: string;
	currentSpaceKey: string | undefined;
	config: KeyableInterface;
	eventParams?: KeyableInterface;
}

export const handleNavigate = () => {
	const { push } = useRouteActions();
	const { createAnalyticsEvent } = useAnalyticsEvents();

	return ({
		searchMore,
		searchResult,
		currentSpaceKey,
		config,
		eventParams,
	}: handleNavigateInterface) => {
		createAnalyticsEvent({
			type: 'sendUIEvent',
			data: {
				action: 'clicked',
				actionSubject: 'liveSearch',
				source: 'liveSearch',
				actionSubjectId: 'liveSearchButtonClicked',
				objectId: eventParams?.pageId,
				attributes: {
					destination: !searchResult ? 'advanced_search' : 'user_defined',
					trigger: eventParams?.trigger || 'click',
					source: 'liveSearch',
					searchSessionId: eventParams?.searchSessionId || undefined,
					navigation: searchMore ? 'postQuery' : 'preQuery',
				},
			},
		}).fire();

		if (searchMore !== undefined && !searchResult) {
			const { spaceKey, type, labels } = config;

			let typeQuery;
			let spaces;
			if (spaceKey === 'currentSpace()' || spaceKey === '@self') {
				spaces = `&spaces=${currentSpaceKey}`;
			} else if (spaceKey) {
				spaces = `&spaces=${spaceKey}`;
			}

			if (type === 'spacedesc') {
				typeQuery = '&type=space';
				// type spacedesc is named space at advance search
			} else if (type) {
				typeQuery = `&type=${type}`;
			}

			const appliedLabels = labels ? `&labels=${labels}` : '';

			push(
				`${ADVANCED_SEARCH.toUrl()}?text=${searchMore}${spaces || ''}${
					typeQuery || ''
				}${appliedLabels}`,
			);
		} else {
			createAnalyticsEvent({
				type: 'sendUIEvent',
				data: {
					action: 'selected',
					actionSubject: 'liveSearchResult',
					actionSubjectId: 'liveSearchQueryResult',
					source: 'liveSearch',
					attributes: {
						source: 'liveSearch',
						searchSessionId: eventParams?.searchSessionId,
						queryHash: eventParams?.queryHash,
						queryVersion: eventParams?.queryVersion,
						queryLength: eventParams?.queryLength,
						wordCount: eventParams?.wordCount,
					},
				},
			}).fire();

			push(`${WIKI.toUrl()}${searchResult}`);
		}
	};
};
