import type { LiveSearchAGGQueryNode } from '../API/AGGSearchTypes';
import type { liveSearchAGGResult } from '../API/handleAGGSearch';

import { CONTEXT_PATH } from './aggVariables';

export const aggResultMapper = ({
	node: result,
}: {
	node: LiveSearchAGGQueryNode;
}): liveSearchAGGResult => ({
	id: result.id,
	title: result.title,
	excerpt: result.excerpt || '',
	spaceName: result.space?.name || '',
	type: result.type,
	url: parseAggUrl(result.url),
});

export const parseAggUrl = (url: string) => {
	const splitUrl = url.split(CONTEXT_PATH);
	return splitUrl[splitUrl.length - 1];
};
