import React from 'react';

import { enrichExcerpt, processExcerpt } from '@confluence/search-utils/entry-points/excerpt';

import {
	SearchResultNormalWrapper,
	SearchResultIconWrapper,
	SearchResultSpace,
	SearchResultExcerpt,
} from '../LiveSearchElementStructure';
import { SearchResultTypeIcon } from '../LiveSearchIcons';
import type { liveSearchAGGResult } from '../API/handleAGGSearch';

interface SearchResultProps {
	searchResult: liveSearchAGGResult;
	onClick: ({ searchResult }: { searchResult: string }) => void;
	extraInfo: 'none' | 'excerpt' | 'space';
}

export const SearchResult = ({ searchResult, onClick, extraInfo }: SearchResultProps) => (
	<SearchResultNormalWrapper onClick={() => onClick({ searchResult: searchResult.url })}>
		<SearchResultIconWrapper contentType={searchResult.type}>
			<SearchResultTypeIcon contentType={searchResult.type} />
		</SearchResultIconWrapper>
		<div>
			<div>{enrichExcerpt(searchResult.title)}</div>
			{extraInfo === 'excerpt' && (
				<SearchResultExcerpt>{processExcerpt(searchResult.excerpt)}</SearchResultExcerpt>
			)}
			{extraInfo === 'space' && (
				<SearchResultSpace>
					{enrichExcerpt(
						searchResult.type !== 'space' ? searchResult.spaceName : searchResult.title,
					)}
				</SearchResultSpace>
			)}
		</div>
	</SearchResultNormalWrapper>
);
