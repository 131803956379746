import type { LiveSearchAGGQueryVariables } from '../API/AGGSearchTypes';
import type { KeyableInterface } from '../types';

import { createAggFilters } from './aggFilters';

type getQueryVariablesForAggSearchArgs = {
	searchInput: string;
	config: KeyableInterface;
	currentSpaceKey: string | undefined;
	confluenceInstance: string;
};

export const CONFLUENCE_EXPERIENCE = 'confluence.liveSearchMacro';
export const CONTEXT_PATH = '/wiki';
export const RESULT_MAX = 5;
export const LABEL_FILTER_SEPARATOR = ',';

// default entities for LSM appear to be different than AS and QS
export const DEFAULT_ENTITIES = [
	'ati:cloud:confluence:page',
	'ati:cloud:confluence:blogpost',
	'ati:cloud:confluence:space',
	'ati:cloud:confluence:comment',
];

export enum ConfluenceATIs {
	PAGE = 'ati:cloud:confluence:page',
	BLOG = 'ati:cloud:confluence:blogpost',
	SPACE = 'ati:cloud:confluence:space',
	COMMENT = 'ati:cloud:confluence:comment',
}

export const getQueryVariablesForAGGSearch = ({
	searchInput,
	config,
	currentSpaceKey,
	confluenceInstance,
}: getQueryVariablesForAggSearchArgs): {
	variables: LiveSearchAGGQueryVariables;
} => {
	const { type, spaceKey, labels } = config;

	return {
		variables: {
			experience: CONFLUENCE_EXPERIENCE,
			query: searchInput,
			first: RESULT_MAX,
			filters: {
				entities: getEntities(type),
				locations: [confluenceInstance],
				confluenceFilters: createAggFilters(spaceKey, currentSpaceKey, labels),
			},
		},
	};
};

export const getEntities = (type: string | undefined) => {
	let entities;
	switch (type) {
		case 'page':
			entities = [ConfluenceATIs.PAGE];
			break;
		case 'blogpost':
			entities = [ConfluenceATIs.BLOG];
			break;
		case 'spacedesc':
			entities = [ConfluenceATIs.SPACE];
			break;
		case 'comment':
			entities = [ConfluenceATIs.COMMENT];
			break;
		default:
			entities = DEFAULT_ENTITIES;
	}
	return entities;
};
