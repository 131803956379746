export const handleLiveSearchNode = (macroNode: any) => {
	const macroParams = macroNode?.attrs?.parameters?.macroParams;

	return {
		labels: macroParams?.labels?.value,
		placeholder: macroParams?.placeholder?.value,
		size: macroParams?.size?.value,
		spaceKey: macroParams?.spaceKey?.value,
		type: macroParams?.type?.value,
		additional: macroParams?.additional?.value,
		macroId: macroNode?.attrs?.parameters?.macroMetadata?.macroId?.value,
	};
};
