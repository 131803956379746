import React, { Fragment } from 'react';

import type { liveSearchAGGResult } from '../API/handleAGGSearch';

import { SearchLoading } from './SearchLoading';
import { SearchResult } from './SearchResult';
import { SearchNoResults } from './SearchNoResults';
import { SearchResultMore } from './SearchResultMore';

interface SearchResultListProps {
	searchResults: liveSearchAGGResult[];
	searchInput: string;
	onClick: (obj: any) => void;
	extraInfo: 'none' | 'excerpt' | 'space';
	loading: boolean;
}

export const SearchResultsList = ({
	searchResults,
	searchInput,
	onClick,
	loading,
	extraInfo,
}: SearchResultListProps) => (
	<div>
		{loading ? (
			<SearchLoading />
		) : searchResults.length > 0 ? (
			<Fragment>
				<div>
					{searchResults.map((result) => (
						<SearchResult
							searchResult={result}
							onClick={onClick}
							extraInfo={extraInfo}
							key={result.id}
						/>
					))}
				</div>
				<SearchResultMore onClick={onClick} searchInput={searchInput} />
			</Fragment>
		) : (
			<SearchNoResults />
		)}
	</div>
);
