import type { FC } from 'react';
import React from 'react';

import Blog16Icon from '@atlaskit/icon-object/glyph/blog/16';
import Image16Icon from '@atlaskit/icon-file-type/glyph/image/16';
import EditorAttachmentIcon from '@atlaskit/icon/glyph/editor/attachment';
import Page16Icon from '@atlaskit/icon-object/glyph/page/16';

export const SearchResultTypeIcon: FC<{ contentType: string }> = ({ contentType }) => {
	switch (contentType) {
		case 'blogpost':
			return <Blog16Icon label="page icon" />;
		case 'image':
			return <Image16Icon label="page icon" />;
		case 'attachment':
			return <EditorAttachmentIcon label="page icon" />;
		case 'page':
		case 'comment':
		default:
			return <Page16Icon label="page icon" />;
	}
};
