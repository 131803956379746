import React from 'react';
import { useIntl, FormattedMessage } from 'react-intl-next';

import {
	SearchNoResultWrapper,
	TextWrapper,
	TextContent,
	TextContentBold,
} from '../LiveSearchElementStructure';
import searchErrorImg from '../img/searchError.svg';
import { i18n } from '../i18n';

export const SearchNoResults = () => {
	const intl = useIntl();
	return (
		<SearchNoResultWrapper>
			<img src={searchErrorImg} alt={intl.formatMessage(i18n.searchIconLabel)} />
			<TextWrapper>
				<TextContentBold>
					<FormattedMessage {...i18n.liveSearchSelectNoResult1} />
				</TextContentBold>
				<TextContent>
					<FormattedMessage {...i18n.liveSearchSelectNoResult2} />
				</TextContent>
			</TextWrapper>
		</SearchNoResultWrapper>
	);
};
