import { defineMessages } from 'react-intl-next';

export const i18n = defineMessages({
	liveSearchLabel: {
		id: 'live-search.label',
		defaultMessage: 'Search',
		description: 'Label for live search button',
	},
	liveSearchTooltip: {
		id: 'live-search.tooltip',
		defaultMessage: 'Live Search Macro',
		description: 'Tooltip for live search macro',
	},
	liveSearchSelectSearchMore: {
		id: 'live-search.search-more',
		defaultMessage: 'See more search results',
		description: 'Label for live search dropdown search more button',
	},
	liveSearchSelectNoResult1: {
		id: 'live-search.no-result-1',
		// TODO: replace straight quotes with curly quotes (see go/curlyquotes)
		// eslint-disable-next-line no-restricted-syntax
		defaultMessage: "We couldn't find anything matching your search",
		description: 'Label for live search dropdown no result line 1',
	},
	liveSearchSelectNoResult2: {
		id: 'live-search.no-result-2',
		defaultMessage: 'Try again with a different term',
		description: 'Label for live search dropdown no result line 2',
	},
	searchIconLabel: {
		id: 'live-search.search-icon-label',
		defaultMessage: 'Magnifying glass',
		description: 'Alt/label text for magnifying glass image or icon',
	},
});
