import React from 'react';
import { FormattedMessage } from 'react-intl-next';

import { SearchResultMoreWrapper } from '../LiveSearchElementStructure';
import { i18n } from '../i18n';

interface SearchResultMoreProps {
	onClick: ({ searchMore }: { searchMore: string }) => void;
	searchInput: string;
}

export const SearchResultMore = ({ onClick, searchInput }: SearchResultMoreProps) => (
	<SearchResultMoreWrapper onClick={() => onClick({ searchMore: searchInput })}>
		<FormattedMessage {...i18n.liveSearchSelectSearchMore} />
	</SearchResultMoreWrapper>
);
