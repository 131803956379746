import React from 'react';

import Spinner from '@atlaskit/spinner/spinner';

import { SearchLoadWrapper } from '../LiveSearchElementStructure';

export const SearchLoading = () => (
	<SearchLoadWrapper>
		<Spinner size="small" />
	</SearchLoadWrapper>
);
