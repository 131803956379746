import type { LiveSearchAGGQueryConfluenceFilters } from '../API/AGGSearchTypes';

import { LABEL_FILTER_SEPARATOR } from './aggVariables';

export const createAggFilters = (
	spaceKey: string,
	currentSpaceKey: string | undefined,
	labels: string,
): LiveSearchAGGQueryConfluenceFilters => {
	const aggConfluenceFilters: LiveSearchAGGQueryConfluenceFilters = {};

	const spaceFilterKey = !spaceKey
		? undefined
		: spaceKey === 'currentSpace()' || spaceKey === '@self'
			? currentSpaceKey
			: spaceKey;
	if (spaceFilterKey) {
		aggConfluenceFilters.spacesFilter = [spaceFilterKey];
	}

	if (labels) {
		const labelFilters = labels.split(LABEL_FILTER_SEPARATOR);
		aggConfluenceFilters.labelsFilter = labelFilters;
	}

	return aggConfluenceFilters;
};
