import { getAGGClient } from '@confluence/graphql';

import { getQueryVariablesForAGGSearch } from '../utils/aggVariables';
import { aggResultMapper } from '../utils/aggResultMapper';
import type { KeyableInterface } from '../types';

import { LiveSearchAGGQuery } from './LiveSearchAGGQuery.agggraphql';
import type { LiveSearchAGGQueryType, LiveSearchAGGQueryVariables } from './AGGSearchTypes';

type handleAGGSearchArgs = {
	searchInput: string;
	config: KeyableInterface;
	currentSpaceKey: string | undefined;
	confluenceInstance: string;
};

export type liveSearchAGGResult = {
	id: string;
	title: string;
	excerpt: string;
	spaceName: string;
	type: string;
	url: string;
};

export const handleAGGSearch = async ({
	searchInput,
	config,
	currentSpaceKey,
	confluenceInstance,
}: handleAGGSearchArgs) => {
	const aggClient = getAGGClient();

	const results = await aggClient
		.query<LiveSearchAGGQueryType, LiveSearchAGGQueryVariables>({
			query: LiveSearchAGGQuery,
			...getQueryVariablesForAGGSearch({
				searchInput,
				config,
				currentSpaceKey,
				confluenceInstance,
			}),
		})
		.then((response) => response.data.search?.search?.edges.map(aggResultMapper));

	return {
		results,
		size: results.length,
	};
};
