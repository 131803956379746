import type { FC } from 'react';
import React, { memo, useState, useEffect } from 'react';
import isEqual from 'lodash/isEqual';

import type { ADNode } from '@atlaskit/editor-common/validator';

import { Attribution, withErrorBoundary } from '@confluence/error-boundary';

import type { KeyableInterface } from './types';
import { handleLiveSearchNode } from './handleADF';
import { LiveSearchTemplate } from './LiveSearchTemplate';

export interface LiveSearchInterface {
	macroNode: ADNode;
}

const LiveSearchComponent: FC<LiveSearchInterface> = ({ macroNode }) => {
	const [liveSearchOptions, setLiveSearchOptions] = useState<KeyableInterface>(
		handleLiveSearchNode(macroNode),
	);

	useEffect(() => {
		setLiveSearchOptions(handleLiveSearchNode(macroNode));
	}, [macroNode]);

	return <LiveSearchTemplate config={liveSearchOptions} />;
};

export const LiveSearch = memo(
	withErrorBoundary({
		attribution: Attribution.SMARTS,
	})(LiveSearchComponent),
	isEqual,
);
