import gql from 'graphql-tag';

export const LiveSearchAGGQuery = gql`
	query LiveSearchAGGQuery(
		$experience: String!
		$query: String!
		$first: Int
		$filters: SearchFilterInput!
	) {
		search {
			search(experience: $experience, query: $query, first: $first, filters: $filters) {
				edges {
					node {
						id
						title
						type
						url
						... on SearchConfluencePageBlogAttachment {
							excerpt
							space {
								name
							}
						}
					}
				}
			}
		}
	}
`;
